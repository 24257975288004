<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> Manage hostel</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Hostel
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn v-if="checkIsAccessible('hostel', 'create')" class="mt-4 btn btn-primary" style="color: #fff" @click="addHostel">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add hostel
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" md="4">
                                    <v-text-field class="form-control" v-model="search.title"
                                        label="Search by title or address" outlined clearable
                                        v-on:keyup.enter="getHostels()" dense>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-text-field class="form-control" v-model="search.phone"
                                        label="Search by phone" outlined clearable
                                        v-on:keyup.enter="getHostels()" dense>
                                    </v-text-field>
                                </v-col>
                                
                                <v-col cols="12" md="4">
                                    <v-btn :loading="loading" @click.prevent="searchHostels()"
                                        class="btn btn-primary w-35 float-right" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Title</th>
                                        <th class="px-3 wrap-column">Warden</th>
                                        <th class="px-3 wrap-column">Status</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="hostels.length > 0" v-for="(item, index) of hostels" :key="index">
                                        <td class="px-3 wrap-column">
                                            {{ item.title }}
                                            <div class="mt-2">
                                                <strong>Address: </strong>
                                                {{ item.address ? item.address : 'NA' }}
                                            </div>

                                            <div class="mt-2">
                                                <strong>Phone: </strong>
                                                {{ item.phone ? item.phone : 'NA' }}
                                            </div>
                                        </td>
                                        
                                        <td class="px-3 wrap-column">
                                            <ul v-if="item.warden_infos.length > 0">
                                                <li v-for="(warden, idx) in item.warden_infos" :key="idx">
                                                    {{ warden.user_personal_id }} |
                                                    {{ warden.first_name }} {{ warden.first_name }} | 
                                                    {{ warden.email }} 
                                                </li>
                                            </ul>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <div class="mt-2">
                                                <span 
                                                    class="badge text-lg "
                                                    v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }">
                                                    {{ item.is_active ? 'Active' : 'Inactive' }}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('hostel', 'edit')">
                                                        <a href="#" class="navi-link" @click="editHostelType(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('hostel', 'delete')">
                                                        <a href="#" class="navi-link" @click="deleteHostelType(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="hostels.length == 0">
                                        <td class="text-center  px-3" colspan="7">
                                          <strong>No data available to display.</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                v-show="hostels.length > 0" 
                                @input="getHostels" 
                                class="pull-right mt-7" 
                                v-model="page"
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number 
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <create-and-update ref="hostel" @refresh="getHostels"></create-and-update>
    </v-app>
</template>

<script>
import HostelService from "@/core/services/hostel/HostelService";
import CreateAndUpdate from "./CreateAndUpdate"

const hostel = new HostelService();

export default {
    components: {
        CreateAndUpdate
    },
    data() {
        return {
            search: {
                title: '',
                phone: ''
            },
            loading: false,
            hostels: [],
            page: null,
            perPage: null,
            total: null,
        }
    },
    methods: {
        searchHostels(){
            this.page = 1;
            this.getHostels();
        },  
        getHostels() {
            this.loading = true;
            hostel
                .paginate(this.search, this.page)
                .then(response => {
                    this.hostels = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                });
        },
        addHostel() {
            this.$refs["hostel"].showModal();
        },
        editHostelType(id) {
            this.$refs["hostel"].showModal(id);
        },
        deleteHostelType(hostelId){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        hostel
                        .delete(hostelId)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.getHostels();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        }
    },
    mounted() {
        this.getHostels();
    }
}
</script>