<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"} hostel` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="cancelForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="hostel.title"
                                outlined
                                dense
                                :error="$v.hostel.title.$error"
                            >
                                <template v-slot:label>
                                    Title <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostel.title.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="hostel.address"
                                outlined
                                dense
                                :error="$v.hostel.address.$error"
                            >
                                <template v-slot:label>
                                    Address <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostel.address.$error">This information is required</span>
                        </v-col>
                        
                        <v-col cols="12">
                            <v-text-field
                                v-model="hostel.phone"
                                outlined
                                dense
                                :error="$v.hostel.phone.$error"
                            >
                                <template v-slot:label>
                                    Phone <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostel.phone.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <v-select
                                v-model="hostel.warden_ids"
                                outlined
                                dense
                                :items="wardens"
                                item-text="display_text"
                                item-value="id"
                                clearable
                                filled
                                @input="hostel.warden_ids = $event !== null ? $event : ''" 
                                attach
                                multiple
                                chips
                                :error="$v.hostel.warden_ids.$error"
                            >
                            <template v-slot:label>
                                Select warden <span class="text-danger">*</span> 
                            </template>
                            <template v-slot:no-data>
                                <div class="no-data-auto-complete text-center">No data available to display. </div>
                            </template>
                            </v-select>
                            <span class="text-danger" v-if="$v.hostel.warden_ids.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <label>
                                Status
                            </label>
                            <v-switch v-model="hostel.is_active" :label="hostel.is_active ? 'Active' : 'Inactive'"></v-switch>
                            <span class="text-danger" v-if="$v.hostel.is_active.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn 
                                    depressed 
                                    @click="cancelForm" 
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>
                                <!-- <v-btn 
                                    v-if="checkIsAccessible('gate-pass', 'create')" 
                                    depressed 
                                    :loading="isBusy"
                                    @click="createOrUpdate" 
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn> -->
                                <v-btn 
                                    depressed 
                                    :loading="isBusy"
                                    @click="createOrUpdate" 
                                    class="text-white ml-2 btn btn-primary"
                                    v-if="checkIsAccessible('hostel', 'create') && checkIsAccessible('hostel', 'edit')"
                                >
                                  {{ edit ? "Update" : "Save" }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import HostelService from "@/core/services/hostel/HostelService";
import UserService from "@/core/services/user/UserService";

const hostel = new HostelService();
const user = new UserService();

export default {
    validations: {
        hostel: {
            title: { required },
            address: {required},
            phone: {required},
            warden_ids: {required},
            is_active: {}
        }
    },
    data() {
        return {
            dialog: false,
            edit: false,
            isBusy: false,
            hostel: {
                title: '',
                address: '',
                phone: '',
                warden_ids: '',
                is_active: true
            },
            wardens: [],
            isWardenLoading : false
        };
    },
    methods: {
        showModal(id = null) {
            this.getWardens();
            if (id) {
                this.edit = true
                this.getHostel(id)
            }else{
                this.resetForm();
            }
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        getHostel(id) {
            hostel
            .show(id)
            .then(response => {
                this.hostel = response.data.hostel;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        createOrUpdate() {
            this.$v.hostel.$touch();
            if (this.$v.hostel.$error) {
                setTimeout(() => {
                    this.$v.hostel.$reset();
                }, 3000);
            } else {
                if (this.edit) {
                    this.updateHostel();
                } else {
                    this.createHostel(this.hostel);
                }
            }
        },
        updateHostel() {
            this.isBusy = true;
            hostel
            .update(this.hostel.id, this.hostel)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.resetForm();
                this.hideModal();
                this.$emit("refresh");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createHostel(fd) {
            this.isBusy = true;
            hostel
            .store(this.hostel)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$snotify.success("Information added");
                this.hideModal();
                this.$emit("refresh");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        cancelForm(){
            this.resetForm();
            this.hideModal();
        },
        resetForm() {
            this.$v.hostel.$reset();
            this.edit = false;
            this.hostel = {
                title: '',
                address: '',
                phone: '',
                warden_ids: '',
                is_active: true
            };
        },
        getWardens(){
            this.isWardenLoading = true;
            
            let data = {
                type: 'warden'
            };

            user
            .filter(data)
            .then((response) => {
                    response.data.users.map((user) => {
                    let data = user;
                    data.display_text =
                        user.full_name +
                        " | " +
                        user.personal_id + 
                        " | " +
                        user.email
                    this.wardens.push(data);
                });
            })
            .catch((err) => {
            })
            .finally(() => (this.isWardenLoading = false));
        }
    },
}
</script>
